
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { calculatePrecision } from 'Lib/utils/quotationAssetPrecisionCalculator';
import Icon from 'UI/Icon.vue';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import StatementsApi from 'Apis/Statements';
import ApiError from 'Entities/ApiError';
import Button from 'Control/Button.vue';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import StatementRequest from 'Entities/statementsPresenter/StatementRequest';
import theme from 'Theme';

interface Props {
    range: any;
    total?: number;
    free?: number;
    hold?: number;
    pnl?: number;
}

interface Data {
    hidePopoverFunction: any;
    theme: any;
}

interface Methods {
    onDatePickerToggle: (toggle: any, hide: any,) => void;
    generateDropdownString: (data: { start: string; end: string; }) => string;
    handleNumber: (data: number) => string;
    onDatePickerInput: (data: any) => void;
    exportExcel: () => void;
}

interface Computed {
    quotationAssetCharacter: string;
    quotationAssetSymbol: string;
    activeAccountId: string;
    precision: number;
    currentUser: InternalUserResponse | undefined;
    isKycVerified: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
    components: {
        Icon,
        Button,
    },
    props: {
        range: {
            type: undefined,
            required: true,
        },
        total: {
            type: Number,
            default: 0,
        },
        free: {
            type: Number,
            default: 0,
        },
        hold: {
            type: Number,
            default: 0,
        },
        pnl: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            hidePopoverFunction: null,
            theme,
        };
    },
    computed: {
        ...mapGetters({
            quotationAssetCharacter: 'Assets/GET_QUOTATION_ASSET_CHARACTER',
            quotationAssetSymbol: 'Assets/GET_QUOTATION_ASSET_SYMBOL',
            activeAccountId: 'Accounts/activeAccountID',
        }),
        precision() {
            return calculatePrecision(this.quotationAssetSymbol);
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
    },
    methods: {
        onDatePickerToggle(toggle, hide) {
            toggle();
            this.hidePopoverFunction = hide;
        },
        generateDropdownString({ start, end }) {
            const parsedStart = start
                .replace(' ', '-')
                .split('-')
                .filter((_, index) => index < 3)
                .join('-');
            const parsedEnd = end
                .replace(' ', '-')
                .split('-')
                .filter((_, index) => index < 3)
                .join('-');
            if (!!new Date(parsedStart) && !!new Date(parsedEnd)) {
                return `${this.$store.getters.getTimeDateString({ timestamp: parsedStart, showTime: false })} - ${this.$store.getters.getTimeDateString({ timestamp: parsedEnd, showTime: false })}`;
            }
            return 'Period';
        },
        handleNumber(num) {
            if (!num && num !== 0) {
                return 'N/A';
            }
            return num.toFixed(this.precision).noExponents().getSeparatedDigits();
        },
        onDatePickerInput(data) {
            this.$emit('change-range', data);
        },
        async exportExcel() {
            try {
                this.$store.commit(SET_LOADING_ON(undefined));
                const { data: res } = await StatementsApi.privateStatementsXlsGetStatements(new StatementRequest({
                    from: (this as any).range.start.valueOf(),
                    to: (this as any).range.end.valueOf(),
                    accountId: this.activeAccountId,
                    quotationAsset: String(this.quotationAssetSymbol),
                }));
                const file = await res.blob();
                const a = document.createElement('a');
                const url = URL.createObjectURL(file);
                a.href = url;
                a.download = 'Statement.xlsx';
                document.body.appendChild(a);
                a.click();
                setTimeout(() => {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 0);
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during loading file, please try again later');
                }
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
    },
});

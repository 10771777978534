
import Vue from 'vue';
import { mapGetters } from 'vuex';

import Icon from 'UI/Icon.vue';
import { SET_PLACEMENT_NAME } from 'Store/v2/LinkPlacement';

interface Props {
    isPageBlocker?: boolean;
    needSetUpTFA?: boolean;
    isPlacementNotLinked?: boolean;
    currentPlacementName?: string;
    isDemoAccBlocker?: boolean;
    customBlockerText?: string;
}

interface Methods {
    linkPlacement: () => void;
}

interface Computed {
    formattedCurrentPlacementName: string;
    isThemeDark: boolean;
}

type NoData = Record<string, never>;

export default Vue.extend<NoData, Methods, Computed, Props>({
    components: {
        Icon,
    },
    props: {
        isPageBlocker: {
            type: Boolean,
            default: false,
        },
        needSetUpTFA: {
            type: Boolean,
            default: false,
        },
        isPlacementNotLinked: {
            type: Boolean,
            default: false,
        },
        currentPlacementName: {
            type: String,
            default: '',
        },
        isDemoAccBlocker: {
            type: Boolean,
            default: false,
        },
        customBlockerText: {
            type: String,
            default: '',
        },
    },
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
        }),
        formattedCurrentPlacementName() {
            if (!this.currentPlacementName) {
                return '';
            }

            return this.$store.state.Placements.placements
                .find(({ name }) => name.toLowerCase() === this.currentPlacementName!.toLowerCase())
                ?.name ?? '';
        },
    },
    methods: {
        async linkPlacement() {
            if (!this.currentPlacementName || !this.formattedCurrentPlacementName) {
                return;
            }

            const placement = this.$store.state.Placements.placements.find((p) => p.name.toLowerCase() === this.currentPlacementName!.toLowerCase());
            if (placement && placement.needExternalKyc) {
                this.$store.commit(SET_PLACEMENT_NAME(this.currentPlacementName!));
                this.$modal.show('confirmLinkModal');
            } else {
                await this.$store.dispatch('Accounts/linkPlacement', this.formattedCurrentPlacementName);
            }
        },
    },
});
